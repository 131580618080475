import React from 'react';
import { Button } from '@when-fertility/shared/domain/common';
import { useViewQuestionnareButtonGetProfileFromS3Mutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import { Modal } from '@when-fertility/shared/domain/common';
import { ApolloError } from '@apollo/client';

type Props = {
  testKitId: string;
};

export const ViewQuestionnaireButton = ({ testKitId }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);
  const [getProfileFromS3, { data, loading }] = useViewQuestionnareButtonGetProfileFromS3Mutation();
  if (!testKitId) {
    return null;
  }

  console.log(error);
  return (
    <>
      <Button
        className="text-white"
        size="small"
        isDisabled={loading}
        onClick={() =>
          getProfileFromS3({
            variables: { input: { id: testKitId } },
            onCompleted: () => {
              setIsModalOpen(true);
            },
            onError: (anError) => {
              setError(anError);
              setIsModalOpen(true);
            },
          })
        }
      >
        {loading ? 'Loading...' : 'View Questionnaire'}
      </Button>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        {!data && error && (
          <div className="flex flex-col justify-center items-center">
            <div className="text-xl mb-4">Sorry there was an error loading this profile.</div>
            <div>{error.message}</div>
          </div>
        )}
        {data?.getProfileFromS3?.items?.map((item, index) => (
          <div key={index} className="mb-4">
            <div className="text-l bold">{item?.key}</div>
            <div className="">{item?.value}</div>
            {item?.values &&
              item?.values.map((value, index) => (
                <div key={index} className="">
                  {value}
                </div>
              ))}
          </div>
        ))}
      </Modal>
    </>
  );
};

ViewQuestionnaireButton.mutation = gql`
  mutation ViewQuestionnareButtonGetProfileFromS3($input: GetProfileFromS3Input!) {
    getProfileFromS3(input: $input) {
      items {
        key
        value
        values
      }
    }
  }
`;
