import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { NURSE_ROUTES } from '../nurse.routes';
import { usePatientAuditHistoryPageGetUserQuery, useGetUserTestKitsQuery } from '@when-fertility/shared/gql/graphql';
import { Icon, Page, Card, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { PatientAuditHistoryItem } from './components/patient-audit-history-item';

export const PatientAuditHistoryPage = () => {
  const [searchParams] = useSearchParams();
  const userId = useMemo(() => searchParams.get(URL_PARAMETERS.userId), [searchParams]);

  const { data: userData } = usePatientAuditHistoryPageGetUserQuery({
    variables: { input: { id: userId || '' } },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  const { data: testKitData } = useGetUserTestKitsQuery({
    variables: { input: { userId } },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  const navigate = useNavigate();

  if (!userId) {
    return null;
  }

  const thisUser = userData?.userById;

  return (
    <Page className="pt-20 md:pt-36">
      <div className="text-2xl flex items-center">
        <div className="hover:cursor-pointer" onClick={() => navigate(`${NURSE_ROUTES.patientProfile}?id=${thisUser?.id}`)}>
          <Icon icon="arrow-left" height={23} className="mr-4" />
          <span>{thisUser?.firstName}&apos;s Audit History</span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6 mt-6">
        <div className="w-full ml-0 mt-6 md:ml-6 md:mt-0">
          <PatientAuditHistoryItem entityName={'user'} userId={userId} header={'Client Account Changes'} />
          <PatientAuditHistoryItem entityName={'profile'} userId={userId} header={'Client Profile Changes'} />
          <PatientAuditHistoryItem entityName={'testkit'} userId={userId} header={'Test-kits Changes'} />
          <PatientAuditHistoryItem entityName={'testkit-report-file'} userId={userId} header={'Reports Changes'} showOldValue={false} />
        </div>
      </div>
    </Page>
  );
};

PatientAuditHistoryPage.fragments = {
  auditLogs: gql`
    fragment AuditLogsFragment on AuditLog {
      entityName
      changedBy
      changeSet
      createdAt
      comment
    }
  `,
  user: gql`
    fragment PatientAuditHistoryPageUserFragment on User {
      id
      firstName
      lastName
      email
    }
  `,
  testKits: gql`
    fragment PatientAuditHistoryPageTestKitsFragment on TestKit {
      id
      status
      resultsStatus
      accessionNumber
      nutripathUpdatedAt
      dateSampleCollected
      activationCode
    }
  `,
};

PatientAuditHistoryPage.query = gql`
  ${PatientAuditHistoryPage.fragments.auditLogs}
  ${PatientAuditHistoryPage.fragments.user}
  ${PatientAuditHistoryPage.fragments.testKits}

  query GetUserAuditLogs($input: AuditLogInput) {
    auditLogs(input: $input) {
      auditLogs {
        ...AuditLogsFragment
      }
    }
  }

  query GetUserTestKits($input: TestKitInput) {
    testKits(input: $input) {
      testKits {
        ...PatientAuditHistoryPageTestKitsFragment
      }
    }
  }

  query PatientAuditHistoryPageGetUser($input: ByIdInput) {
    userById(input: $input) {
      ...PatientAuditHistoryPageUserFragment
    }
  }
`;
